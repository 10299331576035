/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import "../App.css";
import Navbar from "../components/Navbar";
import background from "../assets/background.svg";
import line from "../assets/Line.svg";
import circle from "../assets/Circle.svg";

import icon1 from "../assets/icons/1.png";
import icon2 from "../assets/icons/2.png";
import icon3 from "../assets/icons/3.png";
import icon4 from "../assets/icons/4.png";
import icon5 from "../assets/icons/5.png";
import icon6 from "../assets/icons/6.png";
import icon7 from "../assets/icons/7.png";

import mobile from "../assets/mobile.svg";
import mobileLogo from "../assets/mobile_logo.svg";
import { Link } from "react-router-dom";
import FloatingAnimation from "../components/FloatingAnimation";
import FloatingIcon from "../components/FloatingAnimation";

function Home() {
  const [loading, setLoading] = useState(true);
  const [isScrolled, setIsScrolled] = useState(false);

  const [iconPositions, setIconPositions] = useState<
    {
      left: string;
      top: string;
    }[]
  >([]);

  const icons = [
    {
      icon: icon1,
    },
    {
      icon: icon2,
    },
    {
      icon: icon3,
    },
    {
      icon: icon4,
    },
    {
      icon: icon5,
    },
    {
      icon: icon6,
    },
    {
      icon: icon7,
    },
  ];

  // Memoize initial positions for performance optimization
  const initialPositions = useMemo(
    () =>
      icons.map(() => ({
        left: `${Math.random() * 100}vw`,
        top: `${Math.random() * 100}vh`,
        animationDelay: `${Math.random() * 2}s`,
        opacity: 1, // Initial opacity is fully visible
      })),
    [icons]
  );

  const [positions, setPositions] = useState(initialPositions);

  // Set interval to update positions every 3 seconds
  // Set interval to update positions every 3 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      setPositions(
        icons.map(() => ({
          left: `${Math.random() * 100}vw`,
          top: `${Math.random() * 100}vh`,
          animationDelay: `${Math.random() * 2}s`,
          opacity: 0, // Start fading out
        }))
      );

      // After 0.5 seconds (to let the fade-out happen), reset opacity and change position
      setTimeout(() => {
        setPositions(
          icons.map(() => ({
            left: `${Math.random() * 100}vw`,
            top: `${Math.random() * 100}vh`,
            animationDelay: `${Math.random() * 2}s`,
            opacity: 1, // Fade back in after position change
          }))
        );
      }, 1500); // Delay to ensure fade-out happens before the fade-in
    }, 1500); // Update every 3 seconds

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, [icons]);

  useEffect(() => {
    setLoading(false);

    // const positions = Array(20)
    //   .fill(null)
    //   .map(() => ({
    //     left: `${Math.random() * 100}vw`,
    //     top: `${Math.random() * 100}vh`,
    //   }));

    // setIconPositions(positions);

    const handleScroll = (ev: { scrollY: number }) => {
      if (ev.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("wheel", (ev) => {
      if (ev.deltaY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    });

    window.addEventListener("touchmove", (ev) => {
      if (ev.changedTouches[0].pageY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    });
  }, []);

  if (loading) {
    return (
      <div className="three-body">
        <div className="three-body__dot"></div>
        <div className="three-body__dot"></div>
        <div className="three-body__dot"></div>
      </div>
    );
  }

  return (
    <>
      {/* on desktop top section */}
      <div className={`${isScrolled ? "hidden" : ""}`}>
        <div className="flex items-center justify-center h-[64vh] overflow-hidden bg-transparent gap-8 flex-col">
          <h1
            className="text-4xl font-bold text-center opacity-0 translate-y-10 animate-slide-in"
            style={{ fontFamily: "Lexend", fontWeight: "bold" }}
          >
            SOCCO
          </h1>

          <div
            className="flex items-center gap-8 translate-y-10 animate-slide-in flex-col"
            style={{
              animation:
                "bounce 1600ms infinite cubic-bezier(0.445, 0.05, 0.55, 0.95)",
            }}
          >
            <img src={line} alt="Line" className="absolute" />
            <img src={circle} alt="Circle" className="mt-10" />
          </div>
        </div>

        <div
          className="lg:h-40 md:h-32 h-24 absolute bottom-0 w-full bg-black translate-y-10 animate-slide-in"
          style={{
            // borderRadius: "50% 50% 0 0",
            clipPath: "ellipse(50% 100% at 50% 170%)",
          }}
        >
          <p className="text-white text-center absolute bottom-1 lg:bottom-2 left-1/2 transform -translate-x-1/2 lg:text-lg md:text-base text-xs">
            Scroll for more
          </p>
        </div>
      </div>

      {/* on desktop bottom scroll */}
      <div
        id="scroll-home-desktop"
        className={`${
          isScrolled ? "lg:flex" : "hidden"
        } w-full px-20 gap-5 mt-16 hidden justify-between overflow-hidden translate-y-10 animate-slide-in`}
      >
        <div className="flex flex-col gap-5 mt-24">
          <div className="absolute w-[72%] h-full z-[-1] overflow-y-hidden ">
            {icons.concat(icons, icons, icons).map((icon, index) => (
              <img
                src={icon.icon}
                alt="Mobile"
                className="h-auto icon"
                style={{
                  transform: "translateY(-50%)",
                  position: "absolute",
                  left: `${Math.random() * 100}vw`, // Random initial horizontal position
                  top: `${Math.random() * 100}vh`, // Random initial vertical position
                  animation: `float ${
                    Math.random() * 1 + 1
                  }s ease-in-out infinite`,
                  animationDelay: `${Math.random() * 2}s`, // Random delay for each icon
                  // width: "40px",
                  height: "40px",
                  opacity: positions[index]?.opacity,  // Apply the opacity value
                  transition: "opacity 1.5s ease-in-out",  // Smooth fade effect
                }}
              />
            ))}
          </div>

          <p
            className="text-5xl font-bold"
            style={{
              fontFamily: "Lexend",
            }}
          >
            The
          </p>
          <p
            className="text-6xl font-bold"
            style={{
              fontFamily: "Lexend",
            }}
          >
            Social Commerce
          </p>
          <p
            className="text-[#4A5B63]"
            style={{
              fontFamily: "Lexend",
              fontSize: "15px",
              marginTop: "10px",
            }}
          >
            Socco is a melting pot of consumers, businesses and brands,
            seamlessly
            <br />
            blending storytelling with shopping.
          </p>
          <button className="bg-gradient-to-r from-[#EE8A25] to-[#BC1008] text-white px-4 py-2 rounded-full hover:bg-orange-600 w-[200px]">
            <p className="font-bold">Get Socco</p>
          </button>
        </div>

        <div className="flex justify-end">
          <img src={mobile} alt="Mobile" className="w-full lg:w-5/6 h-auto" />
        </div>

        <div className="absolute bottom-0 translate-y-10 animate-slide-in gap-5 flex overflow-hidden">
          <a href="/privacy">
            <p
              style={{
                fontFamily: "Lexend",
              }}
            >
              Privacy
            </p>
          </a>

          <a href="/terms">
            <p
              style={{
                fontFamily: "Lexend",
              }}
            >
              Terms & Conditions
            </p>
          </a>
        </div>
      </div>

      {/* on mobile */}
      <div className="lg:hidden block w-full">
        <div
          className={`${
            isScrolled ? "flex" : "hidden"
          } lg:hidden translate-y-10 animate-slide-in`}
        >
          <div className="flex flex-col gap-5 md:mt-24 items-center w-full">
            <p
              className="md:text-5xl text-3xl font-bold"
              style={{
                fontFamily: "Lexend",
                textAlign: "center",
              }}
            >
              The
            </p>

            <p
              className="md:text-6xl text-4xl font-bold"
              style={{
                fontFamily: "Lexend",
                textAlign: "center",
              }}
            >
              Social Commerce
            </p>

            <p
              className="text-[#4A5B63]"
              style={{
                fontFamily: "Lexend",
                fontSize: "15px",
                marginTop: "10px",
                textAlign: "center",
              }}
            >
              Socco is a melting pot of consumers, businesses and brands,
              seamlessly
              {/* <br /> */}
              blending storytelling with shopping.
            </p>

            <button className="bg-gradient-to-r from-[#EE8A25] to-[#BC1008] text-white px-4 py-2 rounded-full hover:bg-orange-600 w-[200px] self-center">
              <p className="font-bold">Get Socco</p>
            </button>

            <img
              src={mobileLogo}
              alt="Logo"
              className="w-1/3 md:w-1/4 h-auto mt-10"
            />

            {/* <div className=" ms-5 translate-y-10 animate-slide-in gap-5 flex overflow-hidden self-start">
              <Link to="/privacy">
                <p
                  style={{
                    fontFamily: "Lexend",
                  }}
                  className="md:text-lg text-sm"
                >
                  Privacy
                </p>
              </Link>

              <Link to="/terms">
                <p
                  style={{
                    fontFamily: "Lexend",
                  }}
                  className="md:text-lg text-sm"
                >
                  Terms & Conditions
                </p>
              </Link>
            </div> */}
          </div>

          {/* <div className="absolute w-full h-full z-[-1] overflow-y-hidden">
            {icons.map((icon, index) => (
              <img
                src={icon.icon}
                alt="Mobile"
                className="h-auto icon"
                style={{
                  left: `${Math.random() * 80}vw`, // Random position across viewport width
                  top: `${Math.random() * 50}vh `,
                  bottom: `${Math.random() * 50}vh `,
                  right: `${Math.random() * 80}vw `,
                  animationDelay: `${index * 0.1}s`,
                  animation: `float ${
                    Math.random() * 5 + 5
                  }s ease-in-out infinite`,
                  animationDirection: "reverse",
                  animationTimingFunction: "ease",
                  animationFillMode: "both",
                  animationIterationCount: "infinite",
                  animationName: "float",
                  animationDuration: "6s",
                  animationPlayState: "running",
                  width: "40px",
                  height: "40px",
                }}
              />
            ))}
          </div> */}
        </div>

        <div
          className={`${
            isScrolled ? "flex" : "hidden"
          } lg:hidden  absolute bottom-10 ms-5 translate-y-10 animate-slide-in gap-5 flex overflow-hidden self-start`}
        >
          <Link to="/privacy">
            <p
              style={{
                fontFamily: "Lexend",
              }}
              className="md:text-lg text-sm"
            >
              Privacy
            </p>
          </Link>

          <Link to="/terms">
            <p
              style={{
                fontFamily: "Lexend",
              }}
              className="md:text-lg text-sm"
            >
              Terms & Conditions
            </p>
          </Link>
        </div>
      </div>
    </>
  );
}

export default Home;
