import React from "react";
import header from "../assets/header.png";
import menu from "../assets/menu.svg";

const Navbar: React.FC = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <nav className="p-4 lg:mx-10">
      {/* for desktop */}
      <div
        className="w-full p-4 px-8 lg:flex items-center rounded-full bg-no-repeat bg-center hidden"
        style={{
          backgroundImage: `url(${header})`,
          backgroundSize: "cover",
        }}
      >
        {/* Logo Section */}
        <div className="flex items-center">
          <img src="logo.png" alt="Logo" className="h-10 mr-4" />
        </div>

        {/* Menu and Button Section */}
        <div className="flex flex-row items-center gap-4 ml-auto">
          <ul
            className="flex space-x-16 text-white items-center"
            style={{
              // fontFamily: "Lexend",
              fontWeight: "bold",
            }}
          >
            <li className="text-black cursor-pointer">Blog</li>
            <li className="text-black cursor-pointer">FAQ</li>
            <li className="text-black cursor-pointer">Jobs</li>

            {/* Button */}
            <button className="bg-gradient-to-r from-[#EE8A25] to-[#BC1008] text-white px-4 py-2 rounded-full hover:bg-orange-600">
              Get Socco
            </button>
          </ul>
        </div>
      </div>

      {/* for mobile */}
      <div
        className="w-full lg:hidden items-center rounded-full bg-no-repeat bg-center p-4 overflow-hidden"
        style={{
          backgroundImage: `url(${header})`,
          backgroundSize: "cover",
        }}
      >
        {/* Logo Section */}
        <div
          className="flex items-center cursor-pointer"
          onClick={() => setIsOpen(!isOpen)}
        >
          <img src={menu} alt="Logo" className="h-10 mr-4" />
        </div>

        {/* Menu and Button Section */}
        <div
          className={`${
            isOpen ? "block" : "hidden"
          } flex z-10 flex-col items-center gap-4 absolute mt-8 bg-white p-4 rounded-lg shadow-lg w-[90%]`}
        >
          <ul
            className="flex-col space-y-4 text-white items-center"
            style={{
              fontFamily: "Lexend",
              fontWeight: "bold",
            }}
          >
            <li className="text-black cursor-pointer">Blog</li>
            <li className="text-black cursor-pointer">FAQ</li>
            <li className="text-black cursor-pointer">Jobs</li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
