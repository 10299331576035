import React from "react";

function terms() {
  return (
    <div className="p-8 mx-auto rounded-lg">
      <h1 className="text-6xl font-bold mb-4 text-[#123456] text-center">
        Terms & Conditions
      </h1>
      <p className="text-sm font-bold text-start my-8 text-[#123456]">
        Effective Date: October-07-2024
      </p>

      <section>
        <h2 className="text-3xl font-semibold mb-3 text-[#123456]">
          Welcome to Socco!
        </h2>
        <p className="mb-4 text-[#767676]">
          These Terms & Conditions (“Terms”) govern your use of socco (“the
          Platform”), an online marketplace connecting suppliers and resellers,
          operated by [Your Company Name] (“Company,” “we,” “our,” or “us”), and
          are legally binding under the laws of the State of New Hampshire, USA.
          By using our Platform, you agree to comply with these Terms. If you do
          not agree, you should not use our services.
        </p>
      </section>

      <section>
        <h2 className="text-3xl font-semibold mb-3 text-[#123456]">
          Eligibility
        </h2>
        <p className="mb-4 text-[#767676]">
          By using the Platform, you represent that:
          <ul className="list-disc list-inside pl-2">
            <li>
              You are at least 18 years of age and have the legal capacity to
              enter into a contract.
            </li>
            <li>
              You comply with all applicable local, state, and federal laws
              regarding the use of the Platform.
            </li>
          </ul>
        </p>
      </section>

      <section>
        <h2 className="text-2xl text-[#123456] font-semibold mb-2">
          Account Registration
        </h2>
        <p className="mb-4 text-[#767676]">
          To use certain features of the Platform, you must register and
          maintain an account. You are responsible for ensuring the
          confidentiality of your account information and for all activities
          that occur under your account.
        </p>
      </section>

      <section className="text-[#767676]">
        <h2 className="text-2xl text-[#123456] font-semibold mb-2">Services</h2>
        <p className="mb-4">
          The Platform enables resellers to browse and purchase products listed
          by suppliers. The Company is not responsible for the quality or
          legality of the products listed. Suppliers are responsible for
          compliance with all applicable laws concerning the sale of their
          products.
        </p>

        <ol className="list-decimal list-inside">
          <li>Order Processing & Payments</li>
          <p className="ml-4 mb-2">
            - All prices listed on the Platform are in U.S. Dollars and subject
            to change at the supplier's discretion.
            <br />
            - Resellers agree to make full payment for the products at the time
            of purchase. Payments are processed through our third-party payment
            processors.
            <br />- Suppliers are responsible for shipping products to resellers
            in accordance with their stated policies.
          </p>
          <li>Return & Refund Policy</li>
          <p className="ml-4 mb-2">
            Resellers should adhere to the supplier's return and refund policy,
            which will be provided at the time of purchase. The Company is not
            responsible for disputes between suppliers and resellers regarding
            returns, refunds, or product quality.
          </p>
          <li>User Conduct</li>
          You agree that you will not:
          <ul className="list-disc list-inside pl-2 mb-2">
            <li>
              Engage in any fraudulent, unlawful, or harmful behavior on the
              Platform.
            </li>
            <li>
              Violate any intellectual property rights or privacy rights of the
              Company, suppliers, or other users.
            </li>
            <li>
              Attempt to disrupt or interfere with the proper functioning of the
              Platform.
            </li>
          </ul>
          <li>Termination</li>
          <p className="ml-4 mb-2">
            We reserve the right to suspend or terminate your access to the
            Platform at any time for any violation of these Terms or for any
            other reason deemed necessary by the Company.
          </p>
          <li>Intellectual Property</li>
          <p className="ml-4 mb-2">
            All intellectual property rights related to the Platform, including
            but not limited to trademarks, logos, and content, are the exclusive
            property of the Company or its licensors. Users may not use or
            reproduce any of this material without prior written consent from
            the Company.
          </p>
          <li>Disclaimers</li>
          <p className="ml-4 mb-2">
            The Company does not guarantee the availability, accuracy, or
            quality of any products listed on the Platform. The Platform is
            provided “as is” and “as available” without any warranties of any
            kind, either express or implied.
          </p>
          <li>Limitation of Liability</li>
          <p className="ml-4 mb-2">
            To the fullest extent permitted by applicable law, the Company is
            not liable for any indirect, incidental, or consequential damages
            arising from the use of the Platform, including but not limited to
            loss of profits, data, or goodwill.
          </p>
          <li>Governing Law</li>
          <p className="ml-4 mb-2">
            These Terms are governed by and construed in accordance with the
            laws of the State of New Hampshire, USA, without regard to its
            conflict of law provisions. Any disputes related to these Terms will
            be subject to the exclusive jurisdiction of the state and federal
            courts located in New Hampshire.
          </p>
          <li>Dispute Resolution</li>
          <p className="ml-4 mb-2">
            If any dispute arises, the parties agree to attempt to resolve the
            matter through mediation before pursuing legal action. If mediation
            fails, the dispute shall be resolved by binding arbitration in
            accordance with the rules of the American Arbitration Association.
          </p>
          <li>Amendments</li>
          <p className="ml-4 mb-2">
            We reserve the right to modify these Terms at any time. You will be
            notified of significant changes either through the Platform or via
            email. Your continued use of the Platform after any changes
            constitutes your acceptance of the new Terms.
          </p>
          <li>Contact Us</li>
          <p className="ml-4">
            If you have any questions or concerns regarding these Terms, you can
            contact Wholifi LLC (doing business as “Socco”) at: Email:
            hello@socco.io
          </p>
          <p className="ml-4">Phone: +1-240-509-9993</p>
          <p className="ml-4">
            Mailing Address: 33 Beechstone, #3, Portsmouth, NH, 03801, USA
          </p>
        </ol>
      </section>
    </div>
  );
}

export default terms;
