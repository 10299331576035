import React from "react";

const Navbar: React.FC = () => {
  return (
    <nav className="bg-[#123456] p-4 m-5 mx-5 rounded-full">
      <div className="w-full px-4 flex items-center">
        {/* Logo Section */}
        <div className="flex items-center">
          <img src="logo.png" alt="Logo" className="h-10 mr-4" />
        </div>

        {/* Menu and Button Section */}
        <div className="flex flex-row items-center gap-4 ml-auto">
          <ul className="flex space-x-8 text-white">
            <li>Blog</li>
            <li>FAQ</li>
            <li>Jobs</li>
          </ul>

          {/* Button */}
          <button className="bg-gradient-to-r from-[#EE8A25] to-[#BC1008] text-white px-4 py-2 rounded-full hover:bg-orange-600">
            Get Socco
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
