import React from "react";

function privacy() {
  return (
    <div className="p-8 w-full mx-auto rounded-lg">
      <h1 className="text-6xl font-bold mb-4 text-[#123456] text-center">
        Privacy Policy
      </h1>

      <section className="mt-10">
        <h2 className="text-4xl font-semibold mb-3 text-[#123456]">
          Introduction
        </h2>
        <p className="text-[#767676]">
          Socco is a social commerce platform that primarily caters to small
          businesses, entrepreneurs, and individual resellers. It allows users
          to sell products via community engagement. Socco’s unique selling
          proposition lies in empowering small-scale sellers to reach a larger
          audience without the need for an extensive inventory or logistics
          setup.
        </p>
        <p className="text-[#767676] mt-2">
          At Socco, we are committed to protecting your privacy and safeguarding
          your personal information. This Privacy Policy outlines how we
          collect, use, share, and protect your data when you use our platform.
          By accessing or using our services, you agree to the terms of this
          policy.
        </p>
      </section>

      <section className="mt-7">
        <h2 className="text-4xl font-semibold mb-3 text-[#123456]">
          Information We Collect
        </h2>
        <p className="text-[#767676]">
          Socco is a social commerce platform that primarily caters to small
          businesses, entrepreneurs, and individual resellers. It allows users
          to sell products via community engagement. Socco’s unique selling
          proposition lies in empowering small-scale sellers to reach a larger
          audience without the need for an extensive inventory or logistics
          setup.
        </p>
        <p className="text-[#767676] mt-2">
          At Socco, we are committed to protecting your privacy and safeguarding
          your personal information. This Privacy Policy outlines how we
          collect, use, share, and protect your data when you use our platform.
          By accessing or using our services, you agree to the terms of this
          policy.
        </p>
      </section>

      <section className="mt-7">
        <h2 className="text-2xl font-semibold mb-3 text-[#123456]">
          Personal Information
        </h2>
        <p className="mb-2 text-[#767676]">
          We collect the following personal information:
        </p>
        <div className="text-[#767676]">
          <p>
            - <span className="pl-5">Name</span>
          </p>
          <p>
            - <span className="pl-5">Email Address</span>
          </p>
          <p>
            - <span className="pl-5">Phone Number</span>
          </p>
          <p>
            - <span className="pl-5">Shipping and Billing Address</span>
          </p>
          <p>
            - <span className="pl-5">Payment Information</span>
          </p>
        </div>
      </section>

      <section className="mt-7">
        <h2 className="text-2xl font-semibold mb-3 text-[#123456]">
          Usage Information
        </h2>
        <p className="mb-2 text-[#767676]">We collect data such as:</p>
        <div className="text-[#767676]">
          <p>
            - <span className="pl-5">IP Address</span>
          </p>
          <p>
            - <span className="pl-5">Browser Type and Version</span>
          </p>
          <p>
            - <span className="pl-5">Device Information</span>
          </p>
          <p>
            - <span className="pl-5">Time Zone and Geolocation Data</span>
          </p>
          <p>
            -{" "}
            <span className="pl-5">Browsing Behavior and Purchase History</span>
          </p>
        </div>
      </section>

      <section className="mt-7">
        <h1 className="text-2xl text-[#123456] font-semibold">
          Cookies and Tracking
        </h1>
        <p className="text-[#767676] mt-2">
          At Socco, we are committed to protecting your privacy and safeguarding
          your personal information. This Privacy Policy outlines how we
          collect, use, share, and protect your data when you use our platform.
          By accessing or using our services, you agree to the terms of this
          policy.
        </p>
      </section>

      <section className="mt-10">
        <h2 className="text-4xl font-semibold mb-3 text-[#123456]">
          How We Use Your Information
        </h2>
        <p className="text-[#767676]">We use your data to:</p>
        <div className="text-[#767676]">
          <p>
            - <span className="pl-5">Process orders and arrange shipping</span>
          </p>
          <p>
            -{" "}
            <span className="pl-5">
              Improve our services and personalize your experience
            </span>
          </p>
          <p>
            -{" "}
            <span className="pl-5">
              Send marketing promotions and offers (you can opt-out anytime)
            </span>
          </p>
          <p>
            -{" "}
            <span className="pl-5">
              Provide customer support and ensure compliance with legal
              obligations
            </span>
          </p>
        </div>
      </section>

      <section className="mt-10">
        <h2 className="text-3xl font-semibold mb-3 text-[#123456]">
          Sharing Your Information
        </h2>
        <p className="text-[#767676]">Sharing Your Information</p>
        <div className="text-[#767676]">
          <p>
            -{" "}
            <span className="pl-5">
              Payment Processors (e.g., Stripe, PayPal)
            </span>
          </p>
          <p>
            - <span className="pl-5">Logistics Partners (e.g., FedEx, UPS</span>
          </p>
          <p>
            -{" "}
            <span className="pl-5">Marketing Agencies and Analytics Tools</span>
          </p>
        </div>
      </section>

      <section className="mt-10">
        <h2 className="text-3xl font-semibold mb-3 text-[#123456]">
          Advertising Partners
        </h2>
        <p className="text-[#767676]">
          We share anonymized data with advertising networks for personalized
          ads. You can opt out of interest-based advertising
        </p>
      </section>

      <section className="mt-10">
        <h2 className="text-3xl font-semibold mb-3 text-[#123456]">
          Legal Compliance
        </h2>
        <p className="text-[#767676]">
          We may disclose your information to comply with legal obligations or
          protect our rights.
        </p>
      </section>

      <section className="mt-7">
        <h1 className="text-3xl font-semibold mb-3 text-[#123456]">
          Your Rights
        </h1>
        <div>
          <section className="mt-5">
            <h2 className="text-xl font-semibold mb-3 text-[#123456]">
              Access and Correction
            </h2>
            <p className="text-[#767676]">
              You can request access to or correction of the personal data we
              hold.
            </p>
          </section>
          <section className="mt-5">
            <h2 className="text-xl font-semibold mb-3 text-[#123456]">
              Deletion
            </h2>
            <p className="text-[#767676]">
              You can request deletion of your data, subject to legal
              obligations.
            </p>
          </section>
          <section className="mt-5">
            <h2 className="text-xl font-semibold mb-3 text-[#123456]">
              Data Portability
            </h2>
            <p className="text-[#767676]">
              You can request a copy of your data in a portable format.
            </p>
          </section>
          <section className="mt-5">
            <h2 className="text-xl font-semibold mb-3 text-[#123456]">
              Marketing Preferences
            </h2>
            <p className="text-[#767676]">
              You can opt-out of marketing communications by clicking
              'unsubscribe' in any promotional email
            </p>
          </section>
        </div>
      </section>

      <section className="mt-10">
        <h2 className="text-3xl font-semibold mb-3 text-[#123456]">
          Security of Your Data
        </h2>
        <p className="text-[#767676]">
          We use encryption and secure storage to protect your data. However, no
          online platform can be completely secure.
        </p>
      </section>

      <section className="mt-10">
        <h2 className="text-3xl font-semibold mb-3 text-[#123456]">
          Data Retention
        </h2>
        <p className="text-[#767676]">
          We will retain your personal information only for as long as necessary
          to fulfill the purposes for which we collected it, including for the
          purposes of satisfying any legal, accounting, or reporting
          requirements
        </p>
      </section>

      <section className="mt-10">
        <h2 className="text-3xl font-semibold mb-3 text-[#123456]">
          International Data Transfers
        </h2>
        <p className="text-[#767676]">
          Your information, including personal data, may be transferred to — and
          maintained on — computers located outside of your state, province,
          country, or other governmental jurisdiction where the data protection
          laws may differ from those of your jurisdiction.
        </p>
      </section>

      <section className="mt-10">
        <h2 className="text-3xl font-semibold mb-3 text-[#123456]">
          Children's Privacy
        </h2>
        <p className="text-[#767676]">
          Our services are not intended for children under the age of 13. We do
          not knowingly collect personally identifiable information from anyone
          under the age of 13. If you are a parent or guardian and you are aware
          that your child has provided us with Personal Data, please contact us.
        </p>
      </section>

      <section className="mt-10">
        <h2 className="text-3xl font-semibold mb-3 text-[#123456]">
          California Residents - Your Privacy Rights
        </h2>
        <p className="text-[#767676]">
          If you are a California resident, you have specific rights under the
          California Consumer Privacy Act (CCPA) and California Privacy Rights
          Act (CPRA). These rights include:
        </p>

        <div>
          <section className="mt-5">
            <h2 className="text-xl font-semibold mb-3 text-[#123456]">
              Right to Know
            </h2>
            <p className="text-[#767676]">
              Right to Know: You have the right to request information about the
              personal information we collect about you, including the
              categories of personal information collected, the sources of that
            </p>
          </section>
          <section className="mt-5">
            <h2 className="text-xl font-semibold mb-3 text-[#123456]">
              Right to Delete
            </h2>
            <p className="text-[#767676]">
              You have the right to request the deletion of your personal
              information that we have collected, subject to certain exceptions
            </p>
          </section>
          <section className="mt-5">
            <h2 className="text-xl font-semibold mb-3 text-[#123456]">
              Right to Opt-Out
            </h2>
            <p className="text-[#767676]">
              You have the right to opt-out of the sale of your personal
              information. To exercise this right, please contact us at [insert
              contact information].
            </p>
          </section>
        </div>
      </section>

      <section className="mt-7">
        <div>
          <p className="text-[#767676] text-md">
            <span className="text-black text-xl">Non-Discrimination: </span> We
            will not discriminate against you for exercising any of your rights
            under the CCPA/CPRA.
          </p>
          <p className="text-black text-xl mt-2">
            To exercise your rights, please contact us at [insert contact
            information] and provide sufficient information for us to verify
            your identity.
          </p>
        </div>
      </section>

      <section className="mt-10">
        <h2 className="text-3xl font-semibold mb-3 text-[#123456]">
          Opt-In Policy for SMS Communications
        </h2>

        <div>
          <section className="mt-5">
            <h2 className="text-xl font-semibold mb-3 text-[#123456]">
              Consent
            </h2>
            <p className="text-[#767676]">
              By providing your phone number, you consent to receive marketing
              SMS messages from Socco
            </p>
          </section>
          <section className="mt-5">
            <h2 className="text-xl font-semibold mb-3 text-[#123456]">
              Opt-Out
            </h2>
            <p className="text-[#767676]">
              You can opt-out of SMS communications at any time by replying
              'STOP' to any message you receive.
            </p>
          </section>
          <section className="mt-5">
            <h2 className="text-xl font-semibold mb-3 text-[#123456]">
              Frequency
            </h2>
            <p className="text-[#767676]">
              You will receive SMS messages at varying frequencies depending on
              your preferences and engagement with our services
            </p>
          </section>
          <section className="mt-5">
            <h2 className="text-xl font-semibold mb-3 text-[#123456]">Costs</h2>
            <p className="text-[#767676]">
              Message and data rates may apply. Check with your mobile carrier
              for details
            </p>
          </section>
        </div>
      </section>

      <section className="mt-10">
        <h2 className="text-4xl font-semibold mb-3 text-[#123456]">
          Changes to This Privacy Policy
        </h2>
        <p className="text-[#767676]">
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page. You are
          advised to review this Privacy Policy periodically for any changes.
          Changes to this Privacy Policy are effective when they are posted on
          this page.
        </p>
      </section>

      <section className="mt-10">
        <h2 className="text-4xl font-semibold mb-3 text-[#123456]">
          Contact Us
        </h2>
        <div className="text-[#767676]">
          <p>
            If you have any questions about this Privacy Policy, please contact
            us at:
          </p>
          <p>Email: privacy@socco.io</p>
          <p>Phone: 240-509-9993</p>
          <p>Mailing Address: 33 Beechstone, #3, Portsmouth, NH, 03801, USA</p>
        </div>
      </section>

      <section className="mt-10">
        <h2 className="text-4xl font-semibold mb-3 text-[#123456]">
          Appendix – U.S. Privacy Law Compliance
        </h2>
        <p className="text-[#767676]">
          This section outlines how we comply with relevant U.S. privacy laws
          and regulations, including the CCPA, CPRA, and others as applicable.
        </p>
      </section>
    </div>
  );
}

export default privacy;
